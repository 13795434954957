import { combineReducers, configureStore } from '@reduxjs/toolkit';
import globalReducer from './backend/reducer';

//import storage from 'redux-persist/lib/storage';
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'gwbackend',
    storage,
}

const rootReducer = combineReducers({
    backend: globalReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store)