import { ResponsivePie } from '@nivo/pie';
import HelperService from '../../services/helper.service';

const Pie = ({ data }) => {

    const newData = [];

    let count = 0;

    data.forEach(entry => {
        if (count < 10) {
            let newObject = {
                id: count,
                label: entry.label,
                value: entry.value
            };
            newData.push(newObject);
            count++;
        }
    });

    newData.sort((a, b) => a.value - b.value);

    var colorArray = HelperService.getShopPieColorsBlue();

    let cont;
    if (newData.length) {
        cont = <div className="featuredchart" style={{ height: 400 }}>
            <ResponsivePie
                data={newData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.3}
                padAngle={2}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.5]] }}
                colors={colorArray}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#000"
                arcLinkLabelsThickness={2}
                arcLabelsSkipAngle={10}
                tooltip={point => {
                    return (
                        <div className='rl_tooltip'>
                            <div className='inner'>
                                <div className='color' style={{ backgroundColor: point.datum.color }}></div>
                                <div className='label'>{point.datum.data.label}:</div>
                                <div className='value'>{point.datum.data.value}</div>
                            </div>
                        </div>
                    )
                }}
            />
        </div>
    }

    else {
        cont = 'Es wurden leider noch keine Gutscheine verkauft.'
    }

    return (
        <div className='chartbox'>
            <div className="top">
                <span className="title">Meistverkaufte Gutscheine</span>
            </div>
            <div className="bottom">
                {cont}
            </div>
        </div>
    )
}

export default Pie
