import moment from 'moment';
import "moment/locale/de";
import { jwtDecode } from 'jwt-decode';

class HelperService {

    formatPrice(data) {
        let locale = 'de';
        let options = { style: 'currency', currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2 };
        let formatter = new Intl.NumberFormat(locale, options);
        return (
            formatter.format(data)
        )
    }

    cleanFormData(data, defaultValues = '') {
        const cleanData = Object.entries(data)
            .reduce((obj, [key, value]) => {
                if (typeof (value) === 'undefined') {
                    if (defaultValues[key]) {
                        obj[key] = defaultValues[key];
                    }
                }
                else {
                    obj[key] = value;
                }
                return obj;
            }, {});
        return cleanData
    }


    getChartColorsBlue() {
        return ([
            '#EBF5FB', '#EAF2F8', '#D6EAF8', '#D4E6F1', '#AED6F1', '#A9CCE3', '#85C1E9', '#7FB3D5', '#5DADE2', '#5499C7',
            '#3498DB', '#2980B9', '#2E86C1', '#2471A3', '#2874A6', '#1F618D', '#21618C', '#1A5276',
            '#1B4F72', '#045B8D', '#084081', '#08306B'
        ]);
    }

    getShopPieColorsBlue() {
        return ([
            '#AED6F1', '#A9CCE3', '#85C1E9', '#7FB3D5', '#5DADE2', '#5499C7', '#3498DB', '#2980B9', '#2E86C1', '#2471A3'
        ]);
    }


    handleFormKeyDown(e) {
        if (e.key === 'Enter') {
            if (e.target.classList[0] !== 'ck' && e.target.tagName.toLowerCase() !== 'textarea') {
                e.preventDefault();
            }
        }
    }

    dateToString(date) {
        return moment(date).format('DD.MM.YYYY HH:mm')
    }

    dateToStringWithoutTime(date) {
        return moment(date).format('DD.MM.YYYY')
    }

    dateToIsoString(date) {
        var parts = date.match(/(\d+)/g);
        let newdate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
        return moment(newdate).format('YYYY-MM-DD HH:mm:ss');
    }

    checkAdmin(user) {
        let tokendata = jwtDecode(user.token);
        return tokendata.authorities.includes('SYSTEM_ADMIN') ? true : false;
    }

    checkRedeemUser(user) {
        let tokendata = jwtDecode(user.token);
        return tokendata.authorities.includes('REDEEM') ? true : false;
    }

    checkRechargeUser(user) {
        let tokendata = jwtDecode(user.token);
        return tokendata.authorities.includes('CHARGE') ? true : false;
    }

    checkOnlyOrdersUser(user) {
        let tokendata = jwtDecode(user.token);
        return tokendata.authorities.includes('ORDER_USER') ? true : false;
    }

    getPayedComponents(user) {
        let tokendata = jwtDecode(user.token);
        return tokendata.features;
    }

}

const exportData = new HelperService();
export default exportData;