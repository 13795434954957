export const setApiError = apierror => ({
    type: 'SET_APIERROR',
    apierror
});

export const setUser = user => ({
    type: 'SET_USER',
    user
});

export const setShopName = shopname => ({
    type: 'SET_SHOPNAME',
    shopname
});

export const setNavState = navstate => ({
    type: 'SET_NAVSTATE',
    navstate
});

export const setSaved = saved => ({
    type: 'SET_SAVED',
    saved
});

export const setMuiGridState = muigridstate => ({
    type: 'SET_MUIGRIDSTATE',
    muigridstate
});


export const setAdminUser = adminuser => ({
    type: 'SET_ADMINUSER',
    adminuser
});